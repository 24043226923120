import { Modal } from 'bootstrap';

const openModals = () => {
  const modalElements = document.querySelectorAll('.open-modal-on-load');

  if (modalElements.length > 0) {
    const modalElement = new Modal('.open-modal-on-load');
    modalElement.show();
  }
};

export default openModals;
